import React from "react";
import config from "../coreFiles/config";
import { Link } from "react-router-dom";
import "../component/componentCss/navbar.css";
import Cookies from "js-cookie";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Form,
} from "react-bootstrap";

import { FaArrowRightLong } from "react-icons/fa6";
import { FaUser } from "react-icons/fa6";

const Header = () => {
  let pathArray = window.location.pathname.split("/");
  let path = pathArray[1];
  let loginUserData = Cookies.get("purpleUser")
    ? JSON.parse(Cookies.get("purpleUser"))
    : "";

  return (
    <>
      {/*------------------------- Header Section Start -------------------- */}

      {/* <header>
                <Navbar expand="lg" className="">
                    <Container fluid className='px-lg-4'>
                        <Navbar.Brand >
                            <Link to={`${config.baseUrl}`}>
                                <img src="assets/images/main-logo.svg" alt="logo" className='logo' />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="mx-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >
                                <Link to={`${config.baseUrl}men`} className='active'>Exchange</Link>
                                <Link to={`${config.baseUrl}women`}>Earn</Link>
                                <Link to={`${config.baseUrl}kids`}>
                                    <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.2">
                                            Another action
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.4">
                                            Separated link
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header> */}

      <header className="">
        <Navbar expand="xl">
          <Container>
            <Navbar.Brand>
              <Link to={`${config.baseUrl}`}>
                <img
                  src="assets/images/logo/logo.png"
                  alt="logo"
                  className="logo"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav className=" me-2">
                <Nav.Link
                  href={config.baseUrl}
                  className={
                    path == "" ||
                    path == "exchange" ||
                    path == "exchangedetails"
                      ? "activeNav"
                      : ""
                  }
                >
                  Exchange
                </Nav.Link>
                {/* <Nav.Link
                  href={`${config.baseUrl}earning`}
                  className={path == "earn" ? "activeNav" : ""}
                >
                  Earning
                </Nav.Link> */}

                <Nav.Link
                  href={`${config.baseUrl}swapmanagement`}
                  className={path == "swapmanagement" ? "activeNav" : ""}
                >
                  Swap Management
                </Nav.Link>
                {/* <Nav.Link
                  href={`${config.baseUrl}pur`}
                  className={path == "purpletoken" ? "activeNav" : ""}
                >
                  $PUR
                </Nav.Link> */}
                <Nav.Link
                  href={`${config.baseUrl}support`}
                  className={path == "support" ? "activeNav" : ""}
                >
                  Support
                </Nav.Link>

                {/* <Nav.Link href="javascript:;">Support</Nav.Link> */}

                {/* <NavDropdown title="Information" id="basic-nav-dropdown" className="">
                  <NavDropdown.Item href="#action/3.1">
                    Information
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>

                  <NavDropdown.Item href="#action/3.3">
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Support" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">
                    Support
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
              {/* <Form className="d-flex">
                <Nav.Link href="">
                  <Button
                    variant="primary"
                    className=" border-0 me-4  custombtn"
                  >
                    Login
                  </Button>
                </Nav.Link>
                <Nav.Link href="">
                  <Button
                    variant="primary"
                    className="border-0  custombtn"
                  >
                    Account
                  </Button>
                </Nav.Link>
              </Form> */}

              <div className="head_account">
                <div className="linearrow me-3 d-lg-block d-md-block d-none"></div>
                <Link to="#">
                  <div className="country_box me-3">
                    <img
                      src="assets/icons/country_icon-small.png"
                      alt="country_icon"
                      className="country_icon"
                    />
                  </div>
                </Link>

                <div class="btn_a">
                  {loginUserData == "" ? (
                    <>
                      {/* <Link
                        to={`${config.baseUrl}account`}
                        className="account_icons button cta"
                      >
                        <FaUser fill="#1b1340" /> */}
                        {/* Account
                    <span>
                      <FaArrowRightLong className="account_ico " id="arrow" />
                    </span> */}
                      {/* </Link> */}
                    </>
                  ) : (
                    <>
                      <Link
                        to={`${config.baseUrl}account`}
                        className="account_icons button cta"
                      >
                        <FaUser fill="#6d4aff" />
                        {/* Account
                    <span>
                      <FaArrowRightLong className="account_ico " id="arrow" />
                    </span> */}
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      {/*------------------------- Header Section Exit -------------------- */}
    </>
  );
};

export default Header;
