export const cardData = [
  {
    image: "images/no_kyc.png",
    title: "No",
    span: "KYC",
    description: "Enjoy total anonymity with Purple Swap. No KYC required, swap crypto freely without divulging personal information.",
    link: "Learn More",
  },
  {
    image: "images/Swiss.png",
    title: "Swiss",
    span: "Privacy",
    description: "Purple Swap is based in Switzerland, ensuring your crypto transactions are shielded by some of the world's strictest privacy laws, reinforcing the security of your digital assets.",
    link: "Learn More",
  },
  {
    image: "images/no_signup.png",
    title: "No",
    span: "Sign-Up",
    description: "Dive straight into swapping. Purple Swap eliminates lengthy sign-up processes, making crypto exchange straightforward and instant.",
    link: "Learn More",
  },
  {
    image: "images/best_rate.png",
    title: "Best",
    span: "Rate",
    description: "Get competitive rates with every swap. Purple Swap’s dynamic pricing ensures the best market rates for your crypto exchanges.",
    link: "Learn More",
  },
  {
    image: "images/fast.png",
    title: "Express",
    span:"Swap",
    description: "Every second counts at Purple Swap. Our high-speed engine executes your crypto swaps swiftly, ensuring timely exchanges among over 900+ different cryptocurrencies.",
    link: "Learn More",
  },
  {
    image: "images/24_7.png",
    title: "24/7",
    span:"Support",
    description: "Your queries matter to us. Our dedicated support team is available around the clock to assist you, ensuring a seamless swapping experience.",
    link: "Learn More",
  },
];

export const tabsData = [
  {
    id: 'tab1',
    img: 'assets/simpleStep/s1.png',
    span: 'h_span1',
    backgroundColor: '#383848',
    color: 'white',
    heading: 'Choose a currency pair',
    description:
      'Select your preferred exchange pair from over 50,000 options available on Purple Swap. With such a vast selection, you\'re sure to find the perfect match for your needs.',
  },
  {
    id: 'tab2',
    img: 'assets/simpleStep/s2.png',
    span: 'h_span2',
    backgroundColor: '#383848',
    color: 'white',
    heading: 'Enter the recipient’s address',
    description:
      'Review all the details of your exchange, including an estimated transaction duration. Then, carefully transfer your funds to our secure, one-time-use deposit address.',
  },
  {
    id: 'tab3',
    img: 'assets/simpleStep/s3.png',
    span: 'h_span3',
    backgroundColor: '#383848',
    color: 'white',
    heading: 'Send and receive coins',
    description:
      'Experience the Purple Swap efficiency. We\'ll secure the most favorable exchange rate, efficiently swap your assets, and promptly return them to your account within minutes.',
  },
  {
    id: 'tab4',
    img: 'assets/simpleStep/s4.png',
    span: 'h_span4',
    backgroundColor: '#383848',
    color: 'white',
    heading: 'That\'s all!',
    description:
      'Once you see the exchange status marked as \'Completed,\' your swap is successfully finalized. Access all your transaction details on the completion page for full transparency.',
  },
];
